/* General Layout */
.login-form {
    max-width: 400px;
    margin: 50px auto;
    padding: 40px;
    background-color: #ffffff;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    text-align: center;
}

/* Logo Styles */
.login-logo {
    width: 250px;
    margin-bottom: 20px;
}

/* Heading Styles */
.login-form h2 {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 30px;
    color: #333;
}

/* Input Styles */
input[type="email"],
input[type="password"] {
    width: 100%;
    padding: 10px 15px;
    margin-bottom: 15px;
    border: 1px solid #ddd;
    border-radius: 6px;
    font-size: 16px;
    box-sizing: border-box;
}

/* Button Styles */
button {
    background-color: #000;
    color: white;
    border: none;
    padding: 10px 20px;
    width: 100%;
    margin-bottom: 15px;
    border-radius: 6px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
}

button:hover {
    background-color: #333;
}

/* Google Sign-In Button */
.google-sign-in-button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 6px;
    padding: 10px 20px;
    font-size: 16px;
    color: #444;
    cursor: pointer;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin: 0 auto;
}

.google-sign-in-button:hover {
    background-color: #f5f5f5;
}

.google-icon {
    width: 20px;
    height: 20px;
    margin-right: 10px;
}

span {
    font-weight: bold;
    color: #555;
}

/* Error Message */
.error-message {
    color: red;
    margin-top: 10px;
    font-size: 14px;
}
